import React from 'react';
import styles from './body_projects.module.css';
import { Tabs, List } from 'antd';

const { TabPane } = Tabs;

const steag = [
  {
    title: 'Jet Thai Thailand',
    description: 'Installation of CD-R machines'
  },
  {
    title: 'Aolin China',
    description: 'Overhaul CD-R'
  }
];

const general = [
  {
    title: 'Moser Baer',
    description: 'Disassembly of second hand CD-R lines in Italy'
  },
  {
    title: 'Moser Baer',
    description:
      'Installation and commission of CD-R lines at the Greater Noida plant in India'
  },
  {
    title: 'Global Acatech Thailand',
    description: 'Re-commission of old CD-R lines after log shut down'
  },
  {
    title: 'Rottenegger Bronce',
    description:
      'Installation of additional equipment in new production facility'
  },
  {
    title: 'Rottenegger Bronce',
    description:
      'Re-installation of existing equipment according to German safety standards'
  },
  {
    title: 'Vietnam HoChiMinh City',
    description:
      'Installation, commissioning and training for five 2nd hand DVD machines'
  },
  {
    title: 'Vietnam Danang',
    description:
      'Installation, commissioning and training for five 2nd hand DVD machines'
  },
  {
    title: 'Vietnam Danang',
    description:
      'Installation and commissioning support for Mastering line DVD stamper'
  },
  {
    title: 'Thailand',
    description:
      'Installation and commissioning for 2nd hand HamaTech and KraussMaffei DVD and CD-R lines'
  }
];

const fuji = [
  {
    title: 'Thailand',
    description: 'Pre-installation of Isuzu body shop'
  }
];

const automotive = [
  {
    title: 'Isuzu',
    description: 'Installation and commissioning D-Max and MUX 7: 21 systems'
  },
  {
    title: 'GM Thailand',
    description:
      'Installation and commissioning Cruze Sonic, Captiva and Trailblazer: 22 systems'
  },
  {
    title: 'Ford Thailand',
    description:
      'Installation and commissioning Focus, Eco-Sport, Fiesta: 65 systems'
  },
  {
    title: 'THACO Vietnam',
    description: 'Peugot: 1 system'
  },
  {
    title: 'Auto Alliance Thailand',
    description: 'Ford Everest: 17 systems'
  },
  {
    title: 'Mercedes Vietnam',
    description: 'C-class: 1 system'
  },
  {
    title: 'Mercedes Thailand',
    description: 'C-class: 2 systems'
  },
  {
    title: 'Volvo Trucks Thailand',
    description: '2 systems'
  },
  {
    title: 'Mercedes Thailand',
    description: 'GLA & CLA: 1 system'
  },
  {
    title: 'Mercedes Thailand',
    description: 'All types glazing and panoramic roof: 8 systems'
  },
  {
    title: 'Auto Alliance Thailand',
    description:
      'Glazing cell for Ford Everest and Ranger: 2 systems (KMA general contractor)'
  },
  {
    title: 'Ford',
    description: 'P 375: 10 systems'
  }
];

const projects = () => {
  return (
    <div className={styles.body_projects}>
      <div className={styles.body_projects_content}>
        <div className={styles.body_projects_content_heading}>
          <p className={styles.body_projects_content_heading_main}>
            Reference Projects
          </p>
          <p className={styles.body_projects_content_heading_sub}>
            with a lot of projects on an international scale, we are able to
            bring a fair amount of experience to the table
          </p>
        </div>
        <div className={styles.body_projects_content_grid}>
          <div className={styles.body_projects_content_grid_content}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="General" key="1">
                <div className={styles.body_projects_content_tab}>
                  <List
                    itemLayout="horizontal"
                    dataSource={general}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.title}
                          description={item.description}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab="Steag Hamatech" key="2">
                <div className={styles.body_projects_content_tab}>
                  <List
                    itemLayout="horizontal"
                    dataSource={steag}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.title}
                          description={item.description}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab="Fuji Assembly Japan" key="3">
                <div className={styles.body_projects_content_tab}>
                  <List
                    itemLayout="horizontal"
                    dataSource={fuji}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.title}
                          description={item.description}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
              <TabPane tab="Automotive Projects" key="4">
                <div className={styles.body_projects_content_tab}>
                  <List
                    itemLayout="horizontal"
                    dataSource={automotive}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.title}
                          description={item.description}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default projects;
