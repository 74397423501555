import React from 'react';
import styles from './body_partners.module.css';
import Partner from './partner/partner';

const partners = () => {
  return (
    <div className={styles.body_partners}>
      <div className={styles.body_partners_content}>
        <div className={styles.body_partners_content_heading}>
          <p className={styles.body_partners_content_heading_main}>Partners</p>
          <p className={styles.body_partners_content_heading_sub}>
            we are proud to say that we have partnered up with the following
            Germany companies:
          </p>
        </div>
        <div className={styles.body_partners_content_grid}>
          <Partner
            title="Trotec"
            description="KMA is a authorized distributor for Thailand since 2019"
            link="http://de.trotec.com/"
            link_title="trotec.com"
            logo="TROTEC_Print_CMYK_small.jpg"
          />
          <Partner
            title="Alfda"
            description="KMA is the only authorized distributor for Thailand since the beginning of 2019"
            link="http://www.alfda.de"
            link_title="alfa.de"
            logo="alfda-logo.jpg"
          />
          <Partner
            title="E Dold & Soehne KG"
            description="KMA is the only authorized distributor for Thailand since 2009"
            link="https://en.dold.com"
            link_title="dold.com"
            logo="dold_small.jpg"
          />
          <Partner
            title="Kaleja GmbH"
            description="KMA is the only authorized distributor for SEA except China since 2007"
            link="http://www.kaleja.com"
            link_title="kaleja.com"
            logo="kaleja.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default partners;
