import React from 'react';
import styles from './sensor.module.css';
import { Tag, Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import app from '../../../base';

const Sensor = props => {
  const db = app.firestore();

  const handleDelete = () => {
    db.collection('sensors')
      .doc(props.sensor.id)
      .delete()
      .then(() => {
        message.success(
          'Sensor ' + props.sensor.data.serialNumber + ' deleted'
        );
      });
  };

  return (
    <div className={styles.sensor_wrapper}>
      <p className={styles.sensor_number}>{props.sensor.data.serialNumber}</p>
      <div className={styles.sensor_downloads}>
        <Tag color="green">{props.sensor.data.downloads}</Tag>
      </div>
      {props.sensor.data.hasOwnProperty('geodata') ? (
        <div className={styles.sensor_lat}>
          {props.sensor.data.geodata.latitude}
        </div>
      ) : (
        <></>
      )}
      {props.sensor.data.hasOwnProperty('geodata') ? (
        <div className={styles.sensor_long}>
          {props.sensor.data.geodata.longitude}
        </div>
      ) : (
        <></>
      )}
      <div className={styles.sensor_edit}>
        <Button shape="circle" onClick={handleDelete}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Sensor;
