import React from 'react';
import styles from './body_about.module.css';
import { Timeline } from 'antd';
import {
  CompassOutlined,
  ShopOutlined,
  BuildOutlined,
  ShoppingCartOutlined,
  ScheduleOutlined,
  FlagOutlined
} from '@ant-design/icons';

const about = () => {
  return (
    <div className={styles.body_about}>
      <div className={styles.body_about_content}>
        <div className={styles.body_about_content_heading}>
          <p className={styles.body_about_content_heading_main}>About Us</p>
          <p className={styles.body_about_content_heading_sub}>
            let us introduce ourselves
          </p>
        </div>
        <div className={styles.body_about_content_grid}>
          <div className={styles.body_about_content_grid_text}>
            KMA looks back at 13 years filled with interesting, challenging and
            adventurous times. In all those years, we have had the chance to
            gain a lot of experience by working on various engineering projects
            all over asia.
            <br />
            <br />
            <strong>
              But as of 2019, a new chapter has begun for us as a company.
            </strong>
            <br />
            <br />
            After KMA's longstanding main bussiness contract has suprisingly
            been cancelled, the logical concequence and only professional
            decision was to retire from the automotive sector in order to avoid
            any troubles with the former partner and customers.
            <br />
            <br />
            But since there comes a new beginning with every end, this
            transition gives us the chance to focus on tackling a whole new
            challenge that is near and dear to our hearts.
            <br />
            <br />
            With our move to Chiang Mai, we are preparing ourselves for the next
            chapter for KMA. Our main objective from here on out is not only to
            provide retailers as well as end-users with air purifyers and
            particle measuring devices but to also offer the service along with
            it. With the raising levels in air pollution in Thailand, the issue
            of securing the purity of the air we breathe is more pressing than
            ever. We strive to support every last one of our customers with the
            services neccessary, to ensure that every breath you take at home,
            is as clean and pure as possible.
            <br />
            <br />
            <span className={styles.body_about_content_grid_text_slogan}>
              <strong>KMA - your partner for clean air</strong>
            </span>
          </div>
          <div className={styles.body_about_content_grid_timeline}>
            <Timeline>
              <Timeline.Item dot={<CompassOutlined />} color="green">
                <p>
                  <strong>2019:</strong> moved the office to Chiang Mai
                </p>
              </Timeline.Item>
              <Timeline.Item dot={<ShopOutlined />} color="blue">
                <p>
                  <strong>2015:</strong> officially moved into the new space and
                  picked up operatioins
                </p>
              </Timeline.Item>
              <Timeline.Item dot={<BuildOutlined />} color="blue">
                <p>
                  <strong>May 2014:</strong> start of construction for the new
                  office/work shop
                </p>
                <p>
                  building with 280 m<sup>2</sup> on two floors
                </p>
              </Timeline.Item>
              <Timeline.Item dot={<ShoppingCartOutlined />} color="blue">
                <p>
                  <strong>Q3 2013:</strong> aquisition of land in Chonburi
                </p>
              </Timeline.Item>
              <Timeline.Item dot={<ScheduleOutlined />} color="blue">
                <p>
                  <strong>2013:</strong> began plans for building a tailored
                  building with
                </p>
                <p>shop floor, an area for stocking big and heavy items, an</p>
                <p>area for the SCA demonstration system and a work shop for</p>
                <p>maintenance and overhaul</p>
              </Timeline.Item>
              <Timeline.Item dot={<CompassOutlined />} color="blue">
                <p>
                  <strong>2011:</strong> moved the office to the Chonburi
                  province, closer
                </p>
                <p>
                  to a automotive customer of ours. The new office space was
                </p>
                <p>bigger and had a shop floor and a seperate stock room</p>
              </Timeline.Item>
              <Timeline.Item dot={<CompassOutlined />} color="blue">
                <p>
                  <strong>2008:</strong> moved the office within the Prachinburi
                  province
                </p>
                <p>to a smaller place that included a very small shop floor</p>
                <p>and spare parts room</p>
              </Timeline.Item>
              <Timeline.Item dot={<CompassOutlined />} color="blue">
                <p>
                  <strong>2007:</strong> moved the office to the Prachinburi
                  province
                </p>
                <p>
                  and expanded the office by a seperate room for spare parts
                </p>
              </Timeline.Item>
              <Timeline.Item dot={<FlagOutlined />} color="blue">
                <p>
                  <strong>2006:</strong> established first office in Bangkok
                </p>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
