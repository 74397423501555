import React from 'react';
import './App.css';
import { HashRouter, Route } from 'react-router-dom';

import Header from './components/header/header';
import Body from './components/body_landing/body_landing';
import AboutBody from './components/body_about/body_about';
import AirPurificationBody from './components/body_air/body_air';
import EngineeringBody from './components/body_engineering/body_engineering';
import ProjectsBody from './components/body_projects/body_projects';
import PartnersBody from './components/body_partners/body_partners';
import DownloadBody from './components/body_download/body_download';
import ContactBody from './components/body_contact/body_contact';
import Footer from './components/footer/footer';
import MGMTLogin from './components/body_mgmtlogin/body_mgmtlogin';
import MGMTConsole from './components/body_mgmtconsole/body_mgmtconsole';

import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <div>
          <Route exact path="/" component={Body} />
          <Route exact path="/about" component={AboutBody} />
          <Route
            exact
            path="/air+purification"
            component={AirPurificationBody}
          />
          <Route exact path="/engineering" component={EngineeringBody} />
          <Route exact path="/projects" component={ProjectsBody} />
          <Route exact path="/partners" component={PartnersBody} />
          <Route exact path="/downloads" component={DownloadBody} />
          <Route exact path="/contact" component={ContactBody} />
          <AuthProvider>
            <Route exact path="/mgmt-login" component={MGMTLogin} />
            <PrivateRoute exact path="/mgmt-console" component={MGMTConsole} />
          </AuthProvider>
        </div>
        <Footer />
      </HashRouter>
    </div>
  );
};

export default App;
