import React from 'react';
import styles from './body_engineering.module.css';
import { Collapse } from 'antd';

import Unit from './engineering_unit/engineering_unit';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};

const manualCartridgeRefillStation_sections = [
  {
    img: 'refill_table_2016.jpg',
    text:
      '2015: design and manufacturing of a cartridge refill table for Mercedes Benz CKD plants in collaboration with SCA-Schucker. Delivered 6 units for the Thailand, Malaysia and India plants'
  },
  {
    img: 'refill_table_2018_small.jpg',
    text:
      '2018: slight change of design (different table top, different positioning of pneumatic valves) with SCA-Schucker based on Mercedes Benz requests. Delivered 7 units for the India and Russia plants'
  },
  {
    img: 'refill_table_2019.JPG',
    text:
      '2019: design optimazation for easier ergonomics during production by adding a linear sliding unit to move the fillable cartridge in position and a toggle latch for proper locking in the filling position. Further modification of the frame to aluminum profiles in order to reduce weight. Also modified the table top, bended edges with all joints welded, cleaned and polished new push bottom positioning. Delivered 15 units for the India, Thailand and Malaysia Mercedes Benz plants'
  }
];

const engineering = () => {
  return (
    <div className={styles.body_engineering}>
      <div className={styles.body_engineering_content}>
        <div className={styles.body_engineering_content_heading}>
          <p className={styles.body_engineering_content_heading_main}>
            Engineering
          </p>
          <p className={styles.body_engineering_content_heading_sub}>
            have a look at some of our engineering work
          </p>
        </div>
        <div className={styles.body_engineering_content_grid}>
          <div className={styles.body_engineering_content_grid_intro}>
            <p>
              Generally, all manufacturing is done based on German quality
              requirements and standards, screws are all metric, metal parts are
              powder coated, all units are assembled, commissioned and tested
              prior sending out
            </p>
          </div>
          <div className={styles.body_engineering_content_grid_content}>
            <Collapse bordered={false} defaultActiveKey={['1']}>
              <Panel
                header="Manual Cartridge Refill Station"
                key="1"
                style={customPanelStyle}
              >
                <Unit
                  bannerImage="table.gif"
                  description="The task has been to design and make a simple manual cartridge refill station purely pneumatic without any moving parts, which would require additional safety features"
                  sections={manualCartridgeRefillStation_sections}
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default engineering;
