import React from 'react';
import styles from './footer.module.css';
import { NavLink } from 'react-router-dom';

export default function footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footer_links}>
        <NavLink className={styles.footer_links_navlink} to="/">
          Home
        </NavLink>{' '}
        <br />
        <NavLink className={styles.footer_links_navlink} to="/about">
          About Us
        </NavLink>{' '}
        <br />
        <NavLink className={styles.footer_links_navlink} to="/air+purification">
          Air Purification
        </NavLink>{' '}
        <br />
        <NavLink className={styles.footer_links_navlink} to="/engineering">
          Engineering
        </NavLink>{' '}
        <br />
        <NavLink
          className={styles.footer_links_navlink}
          to="/reference+projects"
        >
          Reference Projects
        </NavLink>{' '}
        <br />
        <NavLink className={styles.footer_links_navlink} to="/partners">
          Partners
        </NavLink>{' '}
        <br />
        <NavLink className={styles.footer_links_navlink} to="/contact">
          Contact
        </NavLink>{' '}
        <br />
      </div>
      <div className={styles.footer_logo}>
        <img src="kma_small.png" alt="Karl Mueller Asia Logo" />
      </div>
      <div className={styles.footer_info}>
        <p>
          Karl Mueller Asia Co., Ltd. (KMA) <br />
          Founded in 2006 <br />
          Tax ID: 01055-49028-58-1 <br />
          Registered paperless importer license <br />
          Registered paperless exporter license <br />
          Official registered for 39 objects of operation <br />
          i.e. trading, service, engineering, consulting,... <br />
          Located in Chiang Mai province (Thailand) <br />
          German management
        </p>
      </div>
    </div>
  );
}
