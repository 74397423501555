import React from 'react';
import styles from './contact_form.module.css';
import { Form, Input, InputNumber, Button, notification } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};
const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a validate email!',
    number: 'Not a validate number!'
  }
};

const ContactForm = () => {
  const onFinish = values => {
    const { name, email, subject, message } = values.email;

    let mail = {
      name: name,
      email: email,
      subject: subject,
      message: message
    };

    axios
      .post(
        'https://europe-west1-karlmueller-asia.cloudfunctions.net/sendMail',
        mail
      )
      .then(response => {
        if (response.status === 200) {
          console.log('success');
        } else {
          console.log('whit...');
        }
      });
  };

  return (
    <div className={styles.contact_form}>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item name={['email', 'name']} label="Name">
          <Input />
        </Form.Item>
        <Form.Item
          name={['email', 'email']}
          label="Email"
          rules={[
            {
              type: 'email',
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['email', 'subject']} label="Subject">
          <Input />
        </Form.Item>
        <Form.Item name={['email', 'message']} label="Message">
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
