import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import styles from './header.module.css';

class SideMenu extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  render() {
    const { location } = this.props;
    return (
      <div className={styles.header}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['/']}
          selectedKeys={[location.pathname]}
        >
          <Menu.Item key="/">
            <NavLink to="/">
              <img
                className={styles.headerLogo}
                src="kmaLogoEigen3.png"
                alt=""
              />
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/about">
            <NavLink to="/about">
              <span>About Us</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/air+purification">
            <NavLink to="/air+purification">
              <span>Air Purification</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/engineering">
            <NavLink to="/engineering">
              <span>Engineering</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/projects">
            <NavLink to="/projects">
              <span>Reference Projects</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/partners">
            <NavLink to="/partners">
              <span>Partners</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/downloads">
            <NavLink to="/downloads">
              <span>Downloads</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/contact">
            <NavLink to="/contact">
              <span>Contact</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default withRouter(SideMenu);
