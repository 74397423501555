import React, { useState } from 'react';
import styles from './product.module.css';
import { Card, Modal, Button } from 'antd';
import {
  SoundOutlined,
  ApiOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const { Meta } = Card;

const showModal = (visible, setVisible) => {
  setVisible(true);
};

const handleOk = (visible, setVisible) => {
  setVisible(false);
};

const handleCancel = (visible, setVisible) => {
  setVisible(false);
};

const Product = (props) => {
  const [visible, setVisible] = useState(false);

  let modalOutput;

  if (props.kind === 'purifyer') {
    modalOutput = (
      <Modal
        style={{ top: 25 }}
        title={props.title}
        visible={visible}
        onCancel={() => handleCancel(visible, setVisible)}
        footer={[
          <a
            className={styles.modal_button_wrapper}
            href={props.data.downloads.manual}
            download={props.data.downloads.manualAlias}
          >
            <Button type="primary" icon="download" key="1">
              Manual
            </Button>
          </a>,
          <a
            className={styles.modal_button_wrapper}
            href={props.data.downloads.datasheet}
            download={props.data.downloads.datasheetAlias}
          >
            <Button type="primary" icon="download" key="2">
              Datasheet
            </Button>
          </a>,
        ]}
      >
        <div className={styles.modal_layout}>
          <div className={styles.modal_layout_features}>
            <p>
              <h4>Features:</h4>
            </p>
            <p>
              <ApiOutlined />{' '}
              <span> {props.data.features.sleep.description}</span>
            </p>
            <p>
              <SoundOutlined />{' '}
              <span> {props.data.features.energy.description}</span>
            </p>
            <p>
              <ClockCircleOutlined />{' '}
              <span> {props.data.features.timer.description}</span>
            </p>
          </div>
          <div className={styles.modal_layout_specs}>
            <p>
              <h4>Specs:</h4>
            </p>
            <p>
              <strong>Min./Max. Power: </strong>
              {props.data.specs.power}
            </p>
            <p>
              <strong>Min./Max. Noise Level: </strong>
              {props.data.specs.noise}
            </p>
            <p>
              <strong>Max. Air Volume: </strong>
              {props.data.specs.volume} m<sup>3</sup>/h
            </p>
            <p>
              <strong>Voltage: </strong>
              {props.data.specs.voltage}
            </p>
            <p>
              <strong>Net Weight: </strong>
              {props.data.specs.netWeight}
            </p>
            {props.data.specs.grossWeight ? (
              <p>
                <strong>Gross Weight: </strong>
                {props.data.specs.grossWeight}
              </p>
            ) : (
              <></>
            )}
            <p>
              <strong>Dimensions: </strong>
              {props.data.specs.dimensions}
            </p>
          </div>
          <div className={styles.modal_layout_image}>
            <img src={props.data.picture} alt="" />
          </div>
        </div>
      </Modal>
    );
  } else {
    modalOutput = (
      <Modal
        style={{ width: '800px' }}
        title={props.title}
        visible={visible}
        onOk={() => handleOk(visible, setVisible)}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => handleCancel(visible, setVisible)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className={styles.modal_layout}>
          <div className={styles.modal_layout_image}>
            <img src={props.data.picture} alt="" />
          </div>
          <div className={styles.modal_layout_main}>
            <div className={styles.modal_layout_main_heading}>
              {props.data.title}
            </div>
            <div className={styles.modal_layout_main_text}>
              {props.data.description}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <a onClick={() => showModal(visible, setVisible)}>
        <Card
          style={{
            height: 'min-content',
            boxShadow: '0 8px 6px -6px black',
          }}
          cover={
            <div className={styles.product_cover}>
              <img
                className={styles.product_cover_logo}
                src={props.data.picture}
                alt="picture of air purifyer"
              />
            </div>
          }
        >
          <Meta title={props.title} />
        </Card>
      </a>
      {modalOutput}
    </>
  );
};

export default Product;
