import React, { useState, useEffect } from 'react';
import styles from './body_download.module.css';
import { Card, Input, Button } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { usePosition } from 'use-position';
import axios from 'axios';
import ReactPlayer from 'react-player';
import app from '../../base';

const Downloads = () => {
  const db = app.firestore();
  const ref = app.storage().ref();

  const [disabled, setDisabled] = useState(true);
  const [input, setInput] = useState('');
  const [sensors, setSensors] = useState([]);
  const [suffix, setSuffix] = useState(<span />);
  const [download, setDownload] = useState('');
  const [enabled, setEnabled] = useState(null);
  const [loading, setLoading] = useState(null);
  const [hiddenButton, setHiddenButton] = useState(
    <Button type="primary" disabled={disabled}>
      Download
    </Button>
  );
  const { latitude, longitude, timestamp, accuracy, error } = usePosition(true);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleDownloadClick = () => {
    let sensor = db.collection('sensors').doc(input);
    sensor.get().then((doc) => {
      let number = doc.data().downloads + 1;
      if (latitude !== undefined && longitude !== undefined) {
        sensor.update({
          downloads: number,
          geodata: { latitude: latitude, longitude: longitude },
        });
      } else {
        sensor.update({
          downloads: number,
        });
      }
    });
  };

  useEffect(() => {
    db.collection('download')
      .get()
      .then((snapshot) => {
        let downloadLink = snapshot.docs[0].cp.proto.fields.link.stringValue;
        setDownload(downloadLink);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    const getEnabled = db.collection('download').onSnapshot((snapshot) => {
      if (snapshot.size) {
        let val = snapshot.docs[0].cp.proto.fields.enabled.booleanValue;
        setEnabled(val);
      }
    });

    return () => {
      getEnabled();
    };
  }, [db]);

  useEffect(() => {
    if (sensors.length == 0) {
      db.collection('sensors')
        .get()
        .then((snapshot) => {
          if (snapshot.size) {
            const data = snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                data: doc.data(),
              };
            });
            setSensors(data);
          }
        });
    } else {
      let sensorNumbers = sensors.map((sensor) => {
        return sensor.data.serialNumber;
      });

      let num = sensorNumbers.indexOf(input);

      if (num > -1 && disabled === true) {
        setDisabled(false);
        setSuffix(<CheckCircleTwoTone twoToneColor="#52c41a" />);
        setHiddenButton(
          <a href={download}>
            <Button
              onClick={handleDownloadClick}
              type="primary"
              disabled={false}
            >
              Download
            </Button>
          </a>
        );
      }

      if (num === -1 && disabled === false) {
        setDisabled(true);
        setSuffix(<span />);
        setHiddenButton(
          <Button type="primary" disabled={true}>
            Download
          </Button>
        );
      }
    }
  });

  //output for when the download section is turned on
  const downloadOutput = (
    <Card style={{ width: 500 }}>
      <div className={styles.body_download_content_grid_element_cardheading}>
        <p
          className={styles.body_download_content_grid_element_cardheading_main}
        >
          Air Quality Monitor Software
        </p>

        <p
          className={styles.body_download_content_grid_element_cardheading_sub}
        >
          Please enter the serialnumber of your KMA Air Quality Sensor
        </p>
      </div>
      <Input
        placeholder="Sensor Serialnumber"
        value={input}
        onChange={handleChange}
        suffix={suffix}
      />
      <div
        className={styles.body_download_content_grid_element_cardbuttonwrapper}
      >
        <div className={styles.body_download_content_grid_element_cardbutton}>
          {hiddenButton}
        </div>
      </div>
    </Card>
  );

  //Output for when then download section is turned off
  const maintenanceOutput = (
    <div className={styles.body_download_maintenance}>
      <div className={styles.body_download_maintenance_img}>
        <img src="download_maintenance.svg" />
      </div>
      The download area is undergoing maintenance right now, please check back
      in later
    </div>
  );

  return (
    <div className={styles.body_download}>
      <div className={styles.body_download_content}>
        <div className={styles.body_download_content_heading}>
          <p className={styles.body_download_content_heading_main}>Downloads</p>
          <p className={styles.body_download_content_heading_sub}>
            We offer custom built monitoring software for our air quality sensor
          </p>
        </div>
        <div className={styles.body_download_content_grid}>
          <div className={styles.body_download_content_grid_element}>
            {enabled === null ? (
              <>
                <LoadingOutlined style={{ fontSize: 35 }} />
              </>
            ) : enabled ? (
              downloadOutput
            ) : (
              maintenanceOutput
            )}
          </div>
          <div className={styles.body_download_content_grid_tutorial}>
            <ReactPlayer url="https://youtu.be/cFfFIlYobBY" />
            <ReactPlayer url="https://youtu.be/PpeiTjGpzQU" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
