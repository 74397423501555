import React from 'react';
import Map from './map';
import ContactForm from './contact_form/contact_form';
import { FormOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import styles from './body_contact.module.css';

const contact = (props) => {
  return (
    <div className={styles.body_contact}>
      <div className={styles.body_contact_content}>
        <div className={styles.body_conact_content_heading}>
          <p className={styles.body_contact_content_heading_main}>
            Any questions?
          </p>
          <p className={styles.body_contact_content_heading_sub}>
            we're more than happy to answer them for you
          </p>
        </div>

        <div className={styles.body_contact_content_body}>
          <div className={styles.body_contact_content_body_info}>
            <div className={styles.body_contact_content_body_info_email}>
              <div
                className={styles.body_contact_content_body_info_email_wrapper}
              >
                <a href="mailto:info@karlmueller-asia.com">
                  <div
                    className={styles.body_contact_content_body_info_email_logo}
                  >
                    <img src="email_logo.png" />
                  </div>
                </a>
              </div>
            </div>
            <div className={styles.body_contact_content_body_info_address}>
              <strong>Karl Mueller Asia Co., Ltd. </strong>
              <br />
              <strong>Service, Engineering & Consulting</strong> <br />
              <br />
              95 Moo 6, Ban Wang Sing Kham, Soi 2, <br />
              Pa Daet, Muang Chiang Mai, Chiang Mai <br />
              50100, Thailand
            </div>
          </div>
          <div className={styles.body_contact_content_map}>
            <div style={{ height: '400px', width: '800px' }}>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default contact;
