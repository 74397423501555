import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyBxkP59x_hAwseXLcx6z01P_c7YO1c6qL4',
  authDomain: 'karlmueller-asia.firebaseapp.com',
  databaseURL: 'https://karlmueller-asia.firebaseio.com',
  projectId: 'karlmueller-asia',
  storageBucket: 'karlmueller-asia.appspot.com',
  messagingSenderId: '582130090945',
  appId: '1:582130090945:web:fd236a85cd886044716540',
  measurementId: 'G-QMV89LP35M'
});

export default app;
