import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Input,
  List,
  message,
  Upload,
  Statistic,
  Card,
  Switch,
} from 'antd';
import {
  UploadOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import app from '../../base';
import 'antd/dist/antd.css';
import { AuthContext } from '../../Auth';
import styles from './body_mgmtconsole.module.css';

import Sensor from './sensor/sensor';
import Map from './map/map';

const MGMTConsole = (props) => {
  //Firebase
  const db = app.firestore();
  const ref = app.storage().ref();

  //Global Variables
  let checked = null;
  const { currentUser } = useContext(AuthContext);

  //State
  const [user, setUser] = useState('');
  const [sensors, setSensors] = useState([]);
  const [newSerial, setNewSerial] = useState([]);
  const [progress, setProgress] = useState(0);
  const [software, setSoftware] = useState('');
  const [uploadName, setUploadName] = useState('');
  const [enabled, setEnabled] = useState(checked);

  const handleSubmit = () => {
    db.collection('sensors')
      .doc(newSerial)
      .set({ downloads: 0, serialNumber: newSerial })
      .then(() => {
        message.success('Sensor ' + newSerial + ' added');
      });
  };

  const switchHandler = (checked) => {
    db.collection('download')
      .doc('aqmonitor_software_download')
      .update({ enabled: checked })
      .then(() => {
        message.success(
          checked ? 'Download area activated' : 'Download area deactivated'
        );
      });
  };

  const getSwitchState = () => {
    db.collection('download')
      .get()
      .then((snapshot) => {
        console.log(snapshot.docs[0].cp.proto.fields.enabled.booleanValue);
        setEnabled(snapshot.docs[0].cp.proto.fields.enabled.booleanValue);
      });
  };

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    customRequest(file) {
      let deleteRef = ref.child('files');
      deleteRef.listAll().then((response) => {
        let filepath = response.items[0].location.path;

        let delRef = ref.child(filepath);
        delRef.delete().then(() => {
          console.log('deleted');
        });
      });

      const name = file.file.name;
      setUploadName(name);
      const metadata = {
        contentType: file.file.type,
      };
      var uploadTask = ref.child('files/' + name).put(file.file, metadata);
      uploadTask.on(
        'state_changed',
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        function (error) {
          message.error('somethin went wrong during the upload... ');
        },
        function () {
          message.success('File successfully uploaded', 5);
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            db.collection('download')
              .doc('aqmonitor_software_download')
              .set({ enabled: enabled, link: downloadURL, name: name })
              .then(() => {
                ref
                  .child('files')
                  .listAll()
                  .then((snapshot) => {
                    console.log(snapshot);
                    setSoftware(snapshot.items[0].name);
                  });
              });
          });
        }
      );
    },
  };

  // triggers only once
  useEffect(() => {
    setUser(currentUser.email);
  }, []);

  // triggers after every render
  useEffect(() => {
    if (enabled == null) {
      getSwitchState();
    }

    ref
      .child('files')
      .listAll()
      .then((snapshot) => {
        if (snapshot.items.length > 0) {
          let name = snapshot.items[0].name;
          if (name !== software) {
            setSoftware(name);
          }
        }
      });
  });

  // Only triggers on db changes
  useEffect(() => {
    const getSensors = db.collection('sensors').onSnapshot((snapshot) => {
      if (snapshot.size) {
        const data = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            data: doc.data(),
          };
        });
        setSensors(data);
      }
    });

    return () => {
      getSensors();
    };
  }, [db]);

  //maps over sensor array and returnes an array with sensor components
  let sensorOutput = sensors.map((sensor) => {
    return <Sensor sensor={sensor} />;
  });

  return (
    <div className={styles.body_mgmt}>
      <div className={styles.body_mgmt_content}>
        <div className={styles.body_mgmt_nav}>
          <p className={styles.body_mgmt_nav_title}>KMA Management Console</p>
          <div className={styles.body_mgmt_nav_user_area}>
            <div className={styles.body_mgmt_nav_user}>
              <span>logged in as: {user}</span>
            </div>
            <div className={styles.body_mgmt_nav_logout}>
              <Button
                type="primary"
                stlye={{ width: 75 }}
                onClick={() => app.auth().signOut()}
              >
                log out
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.body_mgmt_body}>
          <div className={styles.body_mgmt_body_leftpane}>
            <List
              size="small"
              header={
                <div className={styles.body_mgmt_body_tableheader}>
                  <div className={styles.body_mgmt_body_tableheader_title}>
                    Sensors
                  </div>
                  <div className={styles.body_mgmt_body_tableheader_lat}>
                    Latitude
                  </div>
                  <div className={styles.body_mgmt_body_tableheader_long}>
                    Longitude
                  </div>
                  <div className={styles.body_mgmt_body_tableheader_downloads}>
                    downloads
                  </div>
                  <div className={styles.body_mgmt_body_tableheader_edit}>
                    delete
                  </div>
                </div>
              }
              bordered
              dataSource={sensorOutput}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <div className={styles.body_mgmt_body_rightpane}>
            <div className={styles.body_mgmt_body_rightpane_controls}>
              <div className={styles.body_mgmt_body_rightpane_addSensor}>
                <Card
                  style={{
                    boxShadow:
                      '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                  }}
                >
                  <div className={styles.body_mgmt_body_rightpane_input}>
                    <p>Add sensor</p>
                    <Input
                      placeholder="sensor"
                      onChange={(value) => setNewSerial(value.target.value)}
                    />
                  </div>
                  <div className={styles.body_mgmt_body_rightpane_submit}>
                    <Button type="danger" onClick={handleSubmit}>
                      submit
                    </Button>
                  </div>
                </Card>
              </div>

              <div className={styles.body_mgmt_body_rightpane_upload}>
                <Card
                  style={{
                    boxShadow:
                      '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                  }}
                >
                  <div
                    className={styles.body_mgmt_body_rightpane_upload_current}
                  >
                    <div>Current version for download</div>
                    <div
                      className={
                        styles.body_mgmt_body_rightpane_upload_current_name
                      }
                    >
                      {software}
                    </div>
                  </div>
                  <div
                    className={styles.body_mgmt_body_rightpane_upload_control}
                  >
                    <div>Enable/disable download</div>
                    {enabled != null ? (
                      <Switch
                        onChange={(checked) => switchHandler(checked)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={enabled}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>Upload new software</div>
                  <div className={styles.body_mgmt_body_rightpane_upload_btn}>
                    <Upload {...uploadProps}>
                      <Button type="danger">
                        <UploadOutlined /> Click to Upload
                      </Button>
                    </Upload>
                  </div>
                  <div>
                    {progress !== 100 ? (
                      progress === 0 ? (
                        <></>
                      ) : (
                        <div>
                          <Statistic
                            value={progress}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                          />
                          {uploadName}
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MGMTConsole;
