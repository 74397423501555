import React, { useCallback, useContext, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Input, Button } from 'antd';
import 'antd/dist/antd.css';
import app from '../../base';
import { AuthContext } from '../../Auth';
import styles from './body_mgmtlogin.module.css';

const MGMTLogin = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email1, password1 } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email1.value, password1.value);
        history.push('/mgmt-console');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/mgmt-console" />;
  }

  return (
    <div className={styles.body_mgmtlogin_wrapper}>
      <div className={styles.body_mgmtlogin_content}>
        <div className={styles.body_mgmtlogin_content_heading}>
          KMA Management Console
        </div>
        <form onSubmit={handleLogin}>
          <Input
            style={{ marginBottom: 10 }}
            name="email1"
            type="email"
            placeholder="Email"
          />

          <Input
            style={{ marginBottom: 10 }}
            name="password1"
            type="password"
            placeholder="Password"
          />

          <Button type="primary" htmlType="submit">
            log in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default withRouter(MGMTLogin);
