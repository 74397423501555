import React from 'react';
import { Card } from 'antd';
import styles from './partner.module.css';

const { Meta } = Card;

const partner = props => {
  return (
    <Card
      style={{
        height: 'min-content',
        boxShadow: '0 8px 6px -6px black'
      }}
      cover={
        <div className={styles.partner_cover}>
          <img
            className={styles.partner_cover_logo}
            src={props.logo}
            alt={props.alt}
          />
        </div>
      }
    >
      <Meta
        title={props.title}
        description={
          <div>
            <p>{props.description}</p>
            <p>
              <a target="_blank" rel="noopener noreferrer" href={props.link}>
                {props.link_title}
              </a>
            </p>
          </div>
        }
      />
    </Card>
  );
};

export default partner;
