import React, { useState, useEffect } from 'react';
import styles from './body_air.module.css';
import Product from './product/product';
import axios from 'axios';

const Air = () => {
  const [purifyers, setPurifyers] = useState(null);
  const [measuringDevices, setMeasuringDevices] = useState(null);

  useEffect(() => {
    const fetchData = async (db, setter, kind) => {
      const result = await axios(db);
      let output = result.data.map((product, index) => {
        return (
          <Product
            title={product.title}
            data={product.data}
            key={index}
            kind={kind}
          />
        );
      });
      setter(output);
    };
    fetchData('products_purifyers.json', setPurifyers, 'purifyer');
    fetchData(
      'products_measuringDevices.json',
      setMeasuringDevices,
      'measuringDevice'
    );
  }, []);

  return (
    <div className={styles.body_air}>
      <div className={styles.body_air_content}>
        <div className={styles.body_air_content_heading}>
          <p className={styles.body_air_content_heading_main}>
            Air Purification
          </p>
          <p className={styles.body_air_content_heading_sub}>
            take a deep breath and ejoy some clean air
          </p>
        </div>
        <div className={styles.body_air_content_grid}>
          <div className={styles.body_air_content_grid_heading}>
            <p>
              The different air purifier models come with different advantages,
              i.e. ultra quiet sleep mode with dimmed display, automatic mode
              based on particle contamination, indication of particle
              contamination, remote control etc., for detailed information do
              not hesitate to contact us or browse the data sheets and manuals{' '}
              <br /> <br />
              KMA does not just sell air purifiers, we provide the needed
              services as well, keeping all kinds of spare filters, as well as
              repair and clean if necessary. Furthermore we have measuring
              devices to check the proper function of the air purifiers in our
              work shop after cleaning, repair or filter exchange.
            </p>
            <img
              className={styles.body_air_content_grid_heading_img}
              src="ALR550-Schlafen-745x300.jpg"
              alt=""
            />
          </div>
          <div className={styles.body_air_content_grid_layout}>
            <div className={styles.body_air_content_grid_products}>
              {purifyers}
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.body_air_content_grid}>
          <div className={styles.body_air_content_grid_heading}>
            <p>
              In order to provide our custormers with the ability to measure the
              air quality, KMA has partnered up with the German company{' '}
              <a target="_blank" href="https://de.trotec.com/">
                <strong>Trotec</strong>
              </a>{' '}
              to retail their particle measuring devinces
            </p>
            <img
              className={styles.body_air_content_grid_heading_img}
              src="partikelmessgeraet-bq20-76d0.jpg"
              alt=""
            />
          </div>
          <div className={styles.body_air_content_grid_layout}>
            <div className={styles.body_air_content_grid_products}>
              {measuringDevices}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Air;
