import React from 'react';
import styles from './body_landing.module.css';
import { Card } from 'antd';
import 'antd/dist/antd.css';
import logo from './logo.png';
import plant from './plant.png';

export default function body_landing() {
  return (
    <div className={styles.body_landing}>
      <div className={styles.body_landing_content}>
        <div className={styles.body_landing_content_heading}>
          <p>Welcome to Karl Mueller Asia</p>
        </div>
        <div className={styles.body_landing_content_about}>
          <div className={styles.body_landing_content_text}>
            <Card style={{ border: 'none' }}>
              <p className={styles.body_landing_content_text_wrapper}>
                We are generally interested in long lasting business
                relationships to fair conditions for all involved parties. We
                strive to create a 'triple win' situation for customer, partner
                and KMA. Only full dedication to the projects from us and our
                partner can satisfy the customer and with that, gain the needed
                trust. The support of our esteemed partners, the access to their
                global experience and full cooperation enables KMA to give the
                best possible support to the customer and end-user from the
                design stage over installation to production and ultimately
                sales support.
              </p>
            </Card>
          </div>

          <img
            className={styles.body_landing_content_logo}
            src={logo}
            alt="kma logo"
          />
        </div>
        <div className={styles.body_landing_content_air}>
          <img
            className={styles.body_landing_content_logo}
            src={plant}
            alt="kma logo"
          />
          <div className={styles.body_landing_content_text}>
            <Card style={{ border: 'none' }}>
              <p className={styles.body_landing_content_text_wrapper}>
                Based on the raising air pollution in Thailand which can be
                observed on the AQI readings and especially the PM 2.5 levels
                which are most serious to the human lungs, KMA did team up begin
                of 2019 with the reliable and reputable company alfda. Alfda is
                a German company well established on the German and European
                market with, but not only products for air purifications at
                cutting edge technology with continued development. All air
                purifiers can be equipped with different filter types based on
                environmental or personal requirements. KMA as the Thai partner
                of alfda will cover warranty claims within Thailand and holds
                stock of necessary spare parts as well as wear parts, i.e. all
                kind of different HEPA Filters in sizes and types.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
