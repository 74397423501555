import React from 'react';
import styles from './engineering_unit.module.css';

const EngineeringUnit = props => {
  const sections = props.sections.map((section, id) => {
    return (
      <div className={styles.engineering_unit_section}>
        <div className={styles.engineering_unit_section_text}>
          <p>{section.text}</p>
        </div>
        <div className={styles.engineering_unit_section_image}>
          <hr />
          <img src={section.img} />
        </div>
      </div>
    );
  });
  return (
    <div className={styles.engineering_unit}>
      <div className={styles.engineering_unit_banner}>
        <img src={props.bannerImage} />
      </div>
      <div className={styles.engineering_unit_description}>
        <p>
          <strong>{props.description}</strong>
        </p>
      </div>
      {sections}
    </div>
  );
};

export default EngineeringUnit;
