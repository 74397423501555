import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const style = {
  width: '800px',
  height: '400px'
};

const contact = props => {
  return (
    <Map
      google={props.google}
      style={style}
      containerStyle={{ position: 'relative' }}
      zoom={15}
      initialCenter={{ lat: 18.706665, lng: 98.981815 }}
    >
      <Marker
        title={'KMA'}
        name={'KMA'}
        position={{ lat: 18.706665, lng: 98.981815 }}
      />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQH3HuBrOJfB9pvDRIi5Uxayv40rTd03M'
})(contact);
